import axios from 'axios';
import { toast } from 'react-toastify';
import AppRoutes from '../Routes/routesConfig';
import { clearStoredToken, getStoredToken } from './TokenAccess';

/**
 * Creates a pre-configured Axios instance for making HTTP requests to the API.
 *
 * The instance includes default headers, a base URL, and a timeout.
 * It also includes request and response interceptors for handling authentication tokens
 * and error responses.
 */
const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
  },
});

/**
 * Request interceptor that adds the Authorization header if a user token is available.
 */
ApiClient.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('loginToken') || undefined;
    if (token) {
      const newConfig = { ...config };
      newConfig.headers.Authorization = `Bearer ${token}`;
      return newConfig;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

/**
 * Response interceptor that handles HTTP errors globally.
 */
ApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401: // Unauthorized
          clearStoredToken();
          toast.error('Session expired. Please log in again.');
          window.location.href = AppRoutes.loginPage;
          break;
        case 403: // Forbidden
          toast.error('You do not have permission to access this resource.');
          window.location.href = AppRoutes.notFound;
          break;
        case 404: // Not Found
          toast.warning('Requested resource not found.');
          window.location.href = AppRoutes.notFound;
          break;
        case 500: // Internal Server Error
          toast.error('An unexpected error occurred. Please try again later.');
          break;
        default:
          toast.error('An error occurred, please try again.');
          break;
      }
    } else if (error.request) {
      toast.error('Network error, please check your internet connection.');
    } else {
      toast.error('An error occurred, please try again.');
    }

    return Promise.reject(error);
  },
);

export default ApiClient;
