import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import GlobalError from './Components/GlobalError/GlobalError';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const errorMessages = {
  googleClientIdError: 'An Error Occurred!',
};

root.render(
  <React.StrictMode>
    {!clientId ? (
      <GlobalError errorMessage={errorMessages.googleClientIdError} hideRetryButton />
    ) : (
      <GoogleOAuthProvider clientId={clientId}>
        <App />
      </GoogleOAuthProvider>
    )}
  </React.StrictMode>,
);
