import { Header } from '@appkit4/react-components';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { BookingContext } from '../../Contexts/BookingContext';
import { UserContext } from '../../Contexts/UserContext';
import { isUserTokenHasValue } from '../../Utilities/Helper';
import { getStoredToken } from '../../Utilities/TokenAccess';
import styles from './Layout.module.css';

const Layout = ({ children }) => {
  const { onLogout, userData } = useContext(UserContext);
  const { isFloorSelected, isDateSelected, handleClearBookingContext } = useContext(BookingContext);

  return (
    <div className={isFloorSelected && isDateSelected ? `scroller ${styles.layoutContentWithScroll}` : `scroller ${styles.layout}`}>
      <Header
        style={{ backgroundColor: 'black' }}
        titleTemplate={() => <span style={{ color: 'white' }}>ETIC Seating Tool</span>}
        userTemplate={() => {
          if (isUserTokenHasValue(userData?.userToken) && isUserTokenHasValue(userData?.userTower)) {
            return (
              <button
                type="button"
                style={{ color: 'white', backgroundColor: 'transparent', cursor: 'pointer' }}
                onClick={() => {
                  handleClearBookingContext();
                  onLogout();
                }}
              >
                Logout
              </button>
            );
          }
          return <></>;
        }}
      />
      <main className={styles.layoutContent}>{children}</main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
