/**
 * Attempts to import a React component lazily with a retry mechanism.
 * If the import fails and the component hasn't been retried yet, the page will reload.
 * If it has already been retried, the promise will be rejected with the error.
 *
 * @param {Function} componentImport - The dynamic import function for the component.
 * @param {string} name - The name of the component, used for tracking retry attempts in sessionStorage.
 * @returns {Promise} A promise that resolves to the imported component or rejects with an error if retry fails.
 */
const LazyImportRetry = (componentImport, name) =>
  new Promise((resolve, reject) => {
    const sessionStorageKey = `retry-${name}-refreshed`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(sessionStorageKey) || 'false');

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(sessionStorageKey, 'false');
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(sessionStorageKey, 'true');
          window.location.reload();
        } else {
          reject(error);
        }
      });
  });

export default LazyImportRetry;
