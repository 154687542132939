/**
 * Retrieves the stored user token and tower from local storage.
 *
 * @returns {{token: string, tower: string} | undefined} The user token if it exists, otherwise undefined.
 */
export const getStoredToken = () => {
  return { token: localStorage.getItem('userToken'), tower: localStorage.getItem('userTower') } || undefined;
};

/**
 * Stores the user token and user tower in local storage.
 *
 * @param {string} token - The user token to be stored.
 * @param {string} userTower - The user tower to be stored.
 */
export const setStoredToken = (token, userTower) => {
  localStorage.setItem('userToken', token);
  localStorage.setItem('userTower', userTower);
};

/**
 * Clears the stored user token and user tower from local storage.
 */
export const clearStoredToken = () => {
  localStorage.removeItem('userToken');
  localStorage.removeItem('userTower');
};
