import { lazy } from 'react';
import LazyImportRetry from '../Utilities/LazyImportRetry';
import ProtectedPage from './ProtectedPage';
import AppRoutes from './routesConfig';

const LoginPage = lazy(() => LazyImportRetry(() => import('../Pages/Auth/LoginPage/LoginPage'), 'LoginPage'));

const BookingSeatPage = lazy(() => LazyImportRetry(() => import('../Pages/BookingPage/BookingPage'), 'BookingSeatPage'));

const NotFoundPage = lazy(() => LazyImportRetry(() => import('../Pages/NotFoundPage/NotFoundPage'), 'NotFoundPage'));

const SystemRoutes = [
  {
    path: AppRoutes.landingPage,
    element: ProtectedPage(BookingSeatPage),
  },
  {
    path: AppRoutes.loginPage,
    element: LoginPage,
  },
  {
    path: '*',
    element: NotFoundPage,
    isMemo: true,
  },
];

export default SystemRoutes;
