import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { cancelReservation, updateSeatReservationStatus } from '../Services/BookingService';
import { fetchFloorDataForDateAndFloor } from '../Services/FloorService';
import { UserContext } from './UserContext';

export const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  const {
    userData: { userEmail },
  } = useContext(UserContext);

  const [availableChairs, setAvailableChairs] = useState([]);
  const [ellipseClicked, setEllipseClicked] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState('');
  const [startDate, setStartDate] = useState();
  const [reservedChair, setReservedChair] = useState(-1);
  const [userReserved, setUserReserved] = useState(false);
  const [seatName, setSeatName] = useState('');
  const [isFloorSelected, setIsFloorSelected] = useState(false);
  const [floors, setFloors] = useState([]);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [reservationDone, setReservationDone] = useState(false);
  const [toastVisible, setToastVisible] = useState(false);
  const [floorImg, setFloorImg] = useState('');

  const enabledDates = useMemo(() => {
    const today = new Date();
    const dates = [];

    for (let days = 1; dates.length < 3; days += 1) {
      const date = new Date(today);
      date.setDate(today.getDate() + days);
      date.setHours(0, 0, 0, 0);

      if (![5, 6].includes(date.getDay())) {
        dates.push(date);
      }
    }
    return dates;
  }, []);

  const handleEllipseClick = useCallback(
    (index) => {
      const clickedState = Array(availableChairs.length).fill(false);
      clickedState[index] = !ellipseClicked[index];
      setReservedChair(clickedState[index] ? index : -1);
      setEllipseClicked(clickedState);
    },
    [availableChairs, ellipseClicked],
  );

  const handleDoubleClick = useCallback(async () => {
    if (reservedChair === -1) return;

    const chair = availableChairs[reservedChair];
    setSeatName(chair.seatName);

    try {
      const updatedChair = await updateSeatReservationStatus(selectedFloor, chair._id, startDate);

      const updatedChairs = availableChairs.map((c, index) => (index === reservedChair ? updatedChair : c));

      setAvailableChairs(updatedChairs);
      setUserReserved(true);
      setToastVisible(true);
      setReservationDone(true);
    } catch (_) {
      toast.error('Failed to update seat reservation status');
      setToastVisible(false);
      setReservationDone(false);
    }
  }, [availableChairs, reservedChair, selectedFloor, startDate, userEmail]);

  const onDatePicked = (date) => {
    setEllipseClicked([]);
    setAvailableChairs([]);
    if (userReserved) setUserReserved(false);

    if (typeof date === 'object') {
      const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
      setStartDate(formattedDate);
      setIsDateSelected(true);
    } else {
      setStartDate(date);
      setIsDateSelected(true);
    }
  };

  const onFloorSelect = useCallback(
    (floor, floorData) => {
      setSelectedFloor(floor._id);
      setAvailableChairs(floorData.seats || []);
      setUserReserved(floorData.userHasReservation || false);
      setSeatName(floorData.userReservation[0]?.seatName || '');
      setIsFloorSelected(true);
    },
    [setSelectedFloor, setAvailableChairs, setUserReserved, setSeatName, setIsFloorSelected],
  );

  const handleSelectFloor = useCallback(
    async (floorName) => {
      const selectedFloorFound = floors.find((floor) => floor.name === floorName);
      if (!selectedFloorFound) return;

      try {
        const floorData = await fetchFloorDataForDateAndFloor(selectedFloorFound._id, startDate, userEmail);
        onFloorSelect(selectedFloorFound, floorData);
      } catch (_) {
        toast.error('Error fetching floor data');
      }
    },
    [floors, startDate, userEmail, onFloorSelect],
  );

  const onCancelReservation = useCallback(async (floorId, startDate) => {
    try {
      const response = await cancelReservation(floorId, startDate);

      if (response) {
        return {
          success: true,
          message: 'Reservation cancelled successfully',
        };
      }
      return { success: false, message: 'Failed to cancel reservation' };
    } catch (error) {
      if (error.response?.status === 404) {
        return { success: false, message: 'Reservation not found' };
      }
      return {
        success: false,
        message: 'An error occurred while cancelling the reservation',
      };
    }
  }, []);

  const handleCancellation = useCallback(async () => {
    const result = await onCancelReservation(selectedFloor, startDate);

    if (result.success) {
      const updatedChairs = availableChairs.map((chair) => {
        return chair.seatName === seatName ? { ...chair, isReserved: false, reservedBy: '' } : chair;
      });

      const reservedIndex = availableChairs.findIndex((chair) => chair.seatName === seatName);
      const updatedEllipseClicked = ellipseClicked.map((clicked, index) => (index === reservedIndex ? false : clicked));

      setAvailableChairs(updatedChairs);
      setReservedChair(-1);
      setEllipseClicked(updatedEllipseClicked);
      setUserReserved(false);
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
  }, [onCancelReservation, selectedFloor, startDate, userEmail, availableChairs, seatName, ellipseClicked]);

  const handleClearBookingContext = useCallback(() => {
    setAvailableChairs([]);
    setSelectedFloor(null);
    setStartDate(null);
    setIsDateSelected(false);
    setIsFloorSelected(false);
    setEllipseClicked([]);
    setReservedChair(-1);
    setUserReserved(false);
    setSeatName('');
    setToastVisible(false);
    setReservationDone(false);
  }, [
    setAvailableChairs,
    setSelectedFloor,
    setStartDate,
    setIsDateSelected,
    setIsFloorSelected,
    setEllipseClicked,
    setReservedChair,
    setUserReserved,
    setSeatName,
    setToastVisible,
    setReservationDone,
  ]);

  useEffect(() => {
    if (selectedFloor && startDate) {
      fetchFloorDataForDateAndFloor(selectedFloor, startDate, userEmail).then((data) => {
        if (data) {
          if (data.seats) setAvailableChairs(data.seats);
          if (data.svgObjects) setFloorImg(data.svgObjects);
          setUserReserved(data.userHasReservation || false);
          setSeatName(data.userHasReservation ? data.userReservation[0]?.seatName : '');
        }
      });
    }
  }, [selectedFloor, startDate, userEmail]);

  useEffect(() => {
    if (reservationDone && toastVisible) {
      toast.success('Seat reserved successfully');
    }
  }, [reservationDone, toastVisible]);

  const contextValue = useMemo(
    () => ({
      availableChairs,
      setAvailableChairs,
      ellipseClicked,
      handleEllipseClick,
      handleDoubleClick,
      selectedFloor,
      setSelectedFloor,
      startDate,
      setStartDate,
      reservedChair,
      setReservedChair,
      userReserved,
      setUserReserved,
      seatName,
      setSeatName,
      isFloorSelected,
      setIsFloorSelected,
      isDateSelected,
      setIsDateSelected,
      reservationDone,
      setReservationDone,
      toastVisible,
      setToastVisible,
      floorImg,
      setFloorImg,
      floors,
      setFloors,
      enabledDates,
      onDatePicked,
      handleSelectFloor,
      handleCancellation,
      handleClearBookingContext,
    }),
    [
      availableChairs,
      ellipseClicked,
      selectedFloor,
      startDate,
      reservedChair,
      userReserved,
      seatName,
      isFloorSelected,
      isDateSelected,
      reservationDone,
      setReservationDone,
      toastVisible,
      floorImg,
      floors,
      enabledDates,
      onDatePicked,
      handleSelectFloor,
      handleEllipseClick,
      handleDoubleClick,
      handleCancellation,
      handleClearBookingContext,
    ],
  );

  return <BookingContext.Provider value={contextValue}>{children}</BookingContext.Provider>;
};

BookingProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
