import ApiClient from '../Utilities/AxiosInstance';
import { getStoredToken } from '../Utilities/TokenAccess';

export const updateSeatReservationStatus = (floorId, seatId, date) => {
  return ApiClient.put('/reserve', {
    floorId,
    seatId,
    date,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Cancels a seat reservation for a specific floor and user.
 *
 * @param {string} selectedFloor - The ID of the floor where the seat reservation exists.
 * @param {string} userEmail - The email address of the user whose reservation is being canceled.
 * @param {Date} startDate - The date for which the reservation is being canceled.
 * @returns {Promise<Object>} A promise that resolves to the result of the cancellation returned by the API.
 */
export const cancelReservation = (selectedFloor, startDate) => {
  return ApiClient.put('/cancel', {
    floorId: selectedFloor,
    date: startDate,
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
