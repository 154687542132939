import ApiClient from '../Utilities/AxiosInstance';
/**
 * Fetches a list of floors for the specified user tower.
 *
 * @param {string} userTower - The identifier for the user's tower.
 * @returns {Promise<Object>} A promise that resolves to the list of floors for the specified tower.
 */

// Function to fetch floors by tower
export const fetchFloorsByTower = (tower) => {
  return ApiClient.get('/floor', {
    params: { tower },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Fetches floor data for a specific date and floor.
 *
 * The function adjusts the time of the provided date to be 12 hours later in UTC,
 * then makes a request to fetch the floor data for the given floor, date, and user ID.
 *
 * @param {string} floor - The identifier for the floor.
 * @param {Date} date - The date for which to fetch the floor data.
 * @param {string} userId - The identifier of the user.
 * @returns {Promise<Object>} A promise that resolves to the floor data for the specified date and floor.
 */

export const fetchFloorDataForDateAndFloor = (floor, date) => {
  return ApiClient.get(`/floor/${floor}`, {
    params: {
      date,
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
};
