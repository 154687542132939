import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Components/LoadingSpinner/LoadingSpinner';
import { UserContext } from '../Contexts/UserContext';
import { isUserTokenHasValue, isUserTokenUndefined } from '../Utilities/Helper';
import { clearStoredToken, getStoredToken } from '../Utilities/TokenAccess';
import AppRoutes from './routesConfig';

const ProtectedPage = (Component) => {
  const ProtectedComponent = (props) => {
    const navigate = useNavigate();
    const userDataObj = useContext(UserContext);

    useEffect(() => {
      if (isUserTokenUndefined(userDataObj?.userData?.userToken) || isUserTokenUndefined(userDataObj?.userData?.userTower)) {
        navigate(AppRoutes.loginPage);
      }
    }, [userDataObj, navigate]);

    useEffect(() => {
      if (isUserTokenHasValue(getStoredToken()?.token)) {
        if (!isUserTokenHasValue(getStoredToken()?.tower)) {
          clearStoredToken();
          navigate(AppRoutes.loginPage);
        }
      }
    }, [userDataObj]);

    if (isUserTokenHasValue(userDataObj?.userData?.userToken) && isUserTokenHasValue(userDataObj?.userData?.userTower)) {
      return <Component {...props} />;
    }
    return <LoadingSpinner />;
  };

  return ProtectedComponent;
};

ProtectedPage.propTypes = {
  Component: PropTypes.elementType.isRequired,
};

export default ProtectedPage;
