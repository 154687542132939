import { memo } from 'react';
import { Route, BrowserRouter as Router, Routes as ReactRouterRoutes } from 'react-router-dom';

import SystemRoutes from './SystemRoutes';

const renderRoutes = (routes) =>
  routes.map((route, index) => {
    const { path, element: Component, isMemo } = route;

    const RenderComponent = isMemo ? memo(Component) : Component;

    // eslint-disable-next-line react/no-array-index-key
    return <Route key={index} path={path} element={<RenderComponent />} />;
  });

const Routes = () => {
  return (
    <Router>
      <ReactRouterRoutes>{renderRoutes(SystemRoutes)}</ReactRouterRoutes>
    </Router>
  );
};

export default Routes;
