import PropTypes from 'prop-types';
import React from 'react';
import styles from './LoadingSpinner.module.css';

const LoadingSpinner = ({ renderAsPaged = true, whiteText = false, loaderSecondaryColor = false }) => (
  <div className={`${styles.loadingSpinner} ${renderAsPaged && styles.renderAsPaged}`}>
    <div className={loaderSecondaryColor ? styles.spinnerSecondaryColor : styles.spinner} />
    <p className={whiteText && styles.whiteText}>Loading...</p>
  </div>
);

LoadingSpinner.propTypes = {
  renderAsPaged: PropTypes.bool,
  whiteText: PropTypes.bool,
  loaderSecondaryColor: PropTypes.bool,
};

export default LoadingSpinner;
