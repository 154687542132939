export const isUserTokenHasValue = (userToken) => {
  return userToken !== 'undefined' && !!userToken;
};

export const isUserTokenUndefined = (userToken) => {
  return userToken === 'undefined' || userToken === undefined;
};

export const formatDateString = (dateStr) => {
  const date = new Date(dateStr);

  if (Number.isNaN(date.getTime())) {
    throw new Error('Invalid date format. Please use MM/DD/YYYY.');
  }

  const options = { weekday: 'short', month: 'short', day: 'numeric' };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};
