import { jwtDecode } from 'jwt-decode';
import { PropTypes } from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { isUserTokenHasValue } from '../Utilities/Helper';
import { clearStoredToken, getStoredToken, setStoredToken } from '../Utilities/TokenAccess';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // const navigate = useNavigate();
  const storedData = getStoredToken();

  const [userData, setUserData] = useState({
    userTower: storedData?.tower || undefined,
    userName: undefined,
    userEmail: undefined,
    initials: undefined,
    userClientId: undefined,
    userToken: storedData?.token || undefined,
    userId: undefined,
  });

  useEffect(() => {
    try {
      if (isUserTokenHasValue(storedData.token)) {
        const decodedToken = jwtDecode(storedData.token);
        setUserData((prevState) => ({
          ...prevState,
          userName: decodedToken.name,
          userEmail: decodedToken.email,
          userId: decodedToken.userId || undefined,
          userClientId: decodedToken.azp || decodedToken.aud || undefined,
        }));
      }
    } catch (_) {
      toast.error('Failed to decode token. Please try again.');
    }
  }, [storedData.token]);

  const getInitials = useCallback((fullName) => {
    const words = fullName?.split(' ');

    if (words === undefined) return '';

    return words
      .slice(0, 2)
      .map((word) => word.charAt(0))
      .join('');
  }, []);

  const initials = useMemo(() => getInitials(userData.userName), [userData.userName, getInitials]);

  useEffect(() => {
    if (userData.userToken) setStoredToken(userData.userToken, userData.userTower);
    if (userData.userTower) setStoredToken(userData.userToken, userData.userTower);
  }, [userData.userToken, userData.userTower]);

  const onLogout = () => {
    setUserData({
      userTower: undefined,
      userName: undefined,
      userEmail: undefined,
      initials: undefined,
      userClientId: undefined,
      userToken: undefined,
      userId: undefined,
    });

    clearStoredToken();
    // window.location.reload();
    // navigate(AppRoutes.loginPage);
  };

  const value = useMemo(
    () => ({
      userData,
      initials,
      setUserData,
      getInitials,
      onLogout,
    }),
    [userData, initials, setUserData, getInitials, onLogout],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
