import { Loading } from '@appkit4/react-components';
import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './Components/Layout/Layout';
import { BookingProvider } from './Contexts/BookingContext';
import { UserProvider } from './Contexts/UserContext';
import Routes from './Routes/Routes';

const App = () => {
  return (
    <UserProvider>
      <BookingProvider>
        <Layout>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Suspense fallback={<Loading />}>
            <Routes />
          </Suspense>
        </Layout>
      </BookingProvider>
    </UserProvider>
  );
};

export default App;
